<template>
  <div class="edp-backend">
    <div class="preview-top">
      <el-row :gutter="20">
        <el-col :span="9" class="fjc">
          <span class="color-B24747"
            >{{ $t("edpBackendCommon.tip") }}:&ensp;</span
          >
          <span>{{ $t("questionnaire.ThisIsPreviewPageCannotAnswer") }}</span>
        </el-col>
        <el-col :span="6">
          <ul class="fjsbe icon-ul">
            <li
              class="fc"
              :class="{ active: device === 'mobile' }"
              @click="changeDevice('mobile')"
            >
              <span class="el-icon fjc">
                <img
                  src="@/src/assets/common/preview-mobile-white.png"
                  width="35px"
                  height="30px"
                  v-if="device === 'mobile'"
                />
                <img
                  src="@/src/assets/common/preview-mobile-black.png"
                  width="35px"
                  height="30px"
                  v-else
                />
              </span>
              <span class="fjc mb6">{{ $t("edpBackendCommon.mobile") }}</span>
            </li>
            <li
              class="fc"
              :class="{ active: device === 'pc' }"
              @click="changeDevice('pc')"
            >
              <span class="el-icon fjc">
                <img
                  src="@/src/assets/common/preview-pc-white.png"
                  width="30px"
                  height="30px"
                  v-if="device === 'pc'"
                />
                <img
                  src="@/src/assets/common/preview-pc-black.png"
                  width="30px"
                  height="30px"
                  v-else
                />
              </span>
              <span class="fjc mb6">{{ $t("edpBackendCommon.pc") }}</span>
            </li>
            <li class="fc" @click="closeWindow">
              <span class="el-icon fjc">
                <img
                  src="@/src/assets/common/preview-close-black.png"
                  width="30px"
                  height="30px"
                />
              </span>
              <span class="fjc mb6">{{ $t("edpBackendCommon.close") }}</span>
            </li>
          </ul>
        </el-col>
        <el-col :span="8" class="fjsb">
          <div class="fcjsa">
            <p>
              <span class="color-B24747"
                >{{ $t("questionnaire.publishStatus") }} :
              </span>
              <span>{{
                question.status === 1
                  ? $t("questionnaireTemplate.Published")
                  : $t("questionnaireTemplate.Unpublished")
              }}</span>
            </p>
            <p>
              <span class="color-B24747"
                >{{ $t("questionnaire.AuthenticationMode") }} :
              </span>
              <span>{{
                question.releaseChannels ? question.releaseChannels : "--"
              }}</span>
            </p>
          </div>
          <div class="fjc">
            <el-button
              size="small"
              type="primary"
              v-if="question.status === 2"
              @click="goPublish"
              >{{ $t("questionnaire.Publish") }}</el-button
            >
            <el-button
              size="small"
              type="primary"
              v-if="question.status === 1"
              @click="unPublish"
              >{{ $t("questionnaire.Unpublish") }}</el-button
            >
            <el-button size="small" type="primary" @click="goShare">{{
              $t("questionnaire.share")
            }}</el-button>
          </div>
        </el-col>
      </el-row>
    </div>

    <section class="edp-backend-middle" v-if="question">
      <!-- content -->
      <section class="edp-backend-content">
        <div class="edp-backend-content-out">
          <section
            :class="
              device === 'mobile' ? 'mobile' : device === 'pc' ? 'pc' : 'mobile'
            "
          >
            <!-- <quessionnaire :datas="question" /> -->
            <iframe
              :src="`${processPage.VUE_APP_SURVEY_H5}/home?code=${question.code}&channel=h5&preview=edp`"
              style="width: 100%; height: 100%; border: 0 none"
            />
          </section>
        </div>
      </section>
      <!-- content end -->
    </section>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import quessionnaire from "./quessionnaire";
export default {
  name: "preview",
  components: {
    quessionnaire,
  },
  data() {
    return {
      processPage: "",
      publicStatusList: [
        {
          label: this.$t("questionnaireTemplate.Published"),
          value: "1",
        },
        {
          label: this.$t("questionnaireTemplate.Unpublished"),
          value: "2",
        },
      ],
      templateData: [],
      dateils: {
        pageAddress: "string",
        publishSystems: "string",
        qrUrl: "string",
      },
      device: "mobile",
      question: {
        id: undefined,
        code: undefined,
        questions: [],
      },
      win: window,
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(o, n) {},
    },
  },
  mounted() {
    this.id = this.$route.query.id;
    this.code = this.$route.query.code;
    this.processPage = process.env;
    if (this.id) {
      this.question.id = this.id;
      this.viewInit(this.id);
    }
    if (this.code) {
      this.question.code = this.code;
    }
  },
  created() {},
  methods: {
    ...mapActions({
      templateList: "questionnaireTemplate/templateList",
      surveyView: "questionnaireTemplate/surveyView",
      surveyDelete: "questionnaireTemplate/surveyDelete",
      offlineQuestion: "questionnaireTemplate/offlineQuestion",
      onlineQuestion: "questionnaireTemplate/onlineQuestion",
      turnoffQuestion: "questionnaireTemplate/turnoffQuestion",
      turnonQuestion: "questionnaireTemplate/turnonQuestion",
      surveyQrcode: "questionnaireTemplate/surveyQrcode",
    }),
    changeDevice(type) {
      this.device = type;
    },
    closeWindow() {
      const closed = window.close();
      if (!closed) {
        this.$router.go(-1);
      }
    },
    goPublish() {
      this.$router.push({
        path: "backend-questionnaire-publish",
        query: { id: this.id, code: this.code },
      });
    },
    unPublish() {
      this.$confirm(
        `${this.$t("edpBackendCommon.confirm")} ${this.$t(
          "questionnaire.Unpublish"
        )} ${this.$t("questionnaire.thisEvaluation")}`,
        this.$t("edpBackendCommon.noticeTitle"),
        {
          confirmButtonText: this.$t("questionnaire.Unpublish"),
          cancelButtonText: this.$t("edpBackendCommon.return"),
          center: false,
        }
      )
        .then(() => {
          this.offlineQuestion({ id: this.id }).then((res) => {
            if (res.success === true) {
              this.$message({
                type: "success",
                message: "Unpublish Success!",
              });
              this.viewInit(this.id);
            }
          });
        })
        .catch(() => {});
    },
    goShare() {
      if (this.question.status === 1) {
        this.$router.push({
          path: "backend-questionnaire-share",
          query: { id: this.id, code: this.code },
        });
      } else {
        this.$confirm(
          this.$t("questionnaire.unpublishShareTips"),
          this.$t("edpBackendCommon.noticeTitle"),
          {
            confirmButtonText: this.$t("questionnaire.GotoPublish"),
            cancelButtonText: this.$t("edpBackendCommon.return"),
            center: false,
          }
        )
          .then(() => {
            this.goPublish();
          })
          .catch(() => {});
      }
    },
    viewInit(id) {
      this.surveyView({
        id: id,
        param: this.$route.query,
      }).then((res) => {
        if (res.success === true) {
          this.question = res.data;
          if (res.data.coverImg) {
            this.fileImgList = [
              { name: "cover image", url: res.data.coverImg },
            ];
          }
          if (res.data.homePageUrl) {
            this.homeImgList = [
              { name: "home page image", url: res.data.homePageUrl },
            ];
          }
          if (res.data.endPageUrl) {
            this.endImgList = [
              { name: "end page image", url: res.data.endPageUrl },
            ];
          }

          this.question.publishSystems =
            this.question.publishSystems.split(",");
          if (this.question.rewardType) {
            this.question.rewardType = this.question.rewardType + "";
          }
          if (this.question.isPageForEach) {
            this.question.isPageForEach = this.question.isPageForEach + "";
          }
          if (this.question.questions && this.question.questions.length > 0) {
            const questionList = this.question.questions;
            for (let i = 0; i < questionList.length; i++) {
              const qt = questionList[i];
              this.$set(qt, "id", qt.type);
              if (qt.options && qt.options.length > 0) {
                const fileList = [];
                for (let j = 0; j < qt.options.length; j++) {
                  if (qt.type == "2") {
                    if (qt.options[j].isChoose == "1") {
                      qt.options[j].isChoose = true;
                    }
                  }
                  if (qt.type == "3" || qt.type == "4") {
                    const file = {
                      url: qt.options[j].content,
                    };
                    fileList.push(file);
                    if (file.url == qt.options[j].content) {
                      qt.options[j].fileParam = file;
                    }
                  }
                  this.$set(qt, "fileList", fileList);
                  if (qt.type == "8") {
                    qt.options[j].answer = Number(qt.options[j].answer);
                  }
                }
              }
              console.log("qt.options", qt);
            }
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@/src/styles/variable.scss";

.preview-top {
  overflow: hidden;
  height: toPc(70);
  background-color: #fff;
  font-size: toPc(14);
  .color-B24747 {
    color: #b24747;
  }
  .el-row {
    height: 100%;
    .el-col {
      height: 100%;
    }
  }
  ul.icon-ul {
    height: 100%;
    li {
      justify-content: flex-end;
      padding: 0 20px;
      cursor: pointer;
      &.active {
        background-color: #6ab892;
        color: #fff;
        span {
          color: #fff;
          display: inline-flex;
          svg {
            fill: currentColor;
          }
        }
      }
    }
  }
}
.edp-backend-content-out {
  height: 100%;
  .mobile {
    margin: 0 auto;
    max-width: 375px;
    height: 100%;
  }
  .pc {
    margin: 0 auto;
    // max-width: 780px;
    max-width: 375px;
    height: 100%;
  }
}
.searchTitle {
  text-align: left;
  margin-top: 16px;
  img {
    position: absolute;
    margin-top: 2px;
  }
  span {
    margin-left: 20px;
  }
}
.flex-buttons {
  top: 14px;
}
.searchBtn {
  text-align: right;
}
.mt20 {
  margin-top: 16px;
}
.row {
  margin-bottom: 18px;
}
.ftClas {
  color: #3e886d;
  cursor: pointer;
}
.share-wrap {
  border-radius: 10px;
  >>> .el-dialog__header {
    background: rgb(242, 242, 242);
    .titles {
      background: rgb(242, 242, 242);
      width: 90%;
      font-size: 14px;
      color: rgb(0, 9, 91);
      text-align: right;
      i {
        font-size: 14px;
      }
    }
    .el-dialog__close:before {
      font-size: 22px;
      color: #333;
    }
  }
  h4 {
    font-size: 20px;
    text-align: center;
    margin-bottom: 20px;
  }
  .desc {
    font-size: 14px;
    color: rgb(110, 110, 110);
    text-align: center;
  }
  .qrcode {
    display: block;
    width: 140px;
    height: 140px;
    margin: 10px auto;
    padding: 4px;
    border: 1px solid rgb(153, 153, 153);
  }
  .links {
    text-align: center;
    font-size: 14px;
    color: rgb(153, 153, 153);
    span {
      font-size: 12px;
      color: rgb(23, 0, 244);
      text-decoration: underline;
    }
  }
  .copy-btn {
    display: block;
    margin: 5px auto;
  }
}
</style>
<style lang="less">
.questionnaires {
  button {
    cursor: pointer;
  }
  .el-dropdown-link {
    cursor: pointer;
  }
  .el-icon-arrow-down {
    font-size: 14px;
  }
}
</style>
